import clientsApi from "../apis";
import { CLEAR_SESSION_DATA } from "../constants/actionTypes";
import { toast } from "react-toastify";
import { tryLogin } from "./session";

export const getSchools = async (active = 1) => {
  try {
    const response = await clientsApi.get("/admin/schools", { params: { active } });
    return response.data;
  } catch (error) {
    toast.error("כישלון ביצירת לקוח");
    return []
  }
};

export const setSchool = (schoolId) => async (dispatch) => {
  try {
    const response = await clientsApi.patch("/admin/schools", { schoolId });    
    const token = window.localStorage.getItem("token");

    dispatch({
      type: CLEAR_SESSION_DATA,
    });
    await tryLogin(token)(dispatch)
    return response.data;
  } catch (error) {
    toast.error("כישלון ביצירת לקוח");
    return []
  }
};

export const getStudents = async (schoolId, branchId = 1, userType = 1) => {
  try {
    const response = await clientsApi.get(
      `/admin/school/${schoolId}/students`
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getStudentIncomes = async (schoolId, studentId) => {
  try {
    const response = await clientsApi.get(`/incomes/getStudentIncomes/${schoolId}/${studentId}`);
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const deleteStudents = async (schoolId, ids) => {
  try {
    const response = await clientsApi.post(
      `/admin/school/${schoolId}/delete-students`, { ids }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getSchedulers = async (schoolId, query) => {
  try {
    const response = await clientsApi.get(
      `/admin/school/${schoolId}/schedulers`, {
        params: query
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const deleteSchedulers = async (schoolId, ids) => {
  try {
    const response = await clientsApi.post(
      `/admin/school/${schoolId}/delete-schedulers`, { ids }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return 0;
  }
};

export const getClients = async (schoolId) => {
  try {
    const response = await clientsApi.get(
      `/admin/school/${schoolId}/clients`
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const deleteClient = async (schoolId, clientId) => {
  try {
    const response = await clientsApi.delete(
      `/admin/school/${schoolId}/clients/${clientId}`
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return 0;
  }
};

export const getFundingBodies = async (schoolId) => {
  try {
    const response = await clientsApi.get(
      `/admin/school/${schoolId}/funding-bodies`
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const deleteFundingBodies = async (schoolId, fbId) => {
  try {
    const response = await clientsApi.delete(
      `/admin/school/${schoolId}/funding-bodies/${fbId}`
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return 0;
  }
};

export const getInvoices = async (schoolId, query = {}) => {
  try {
    const response = await clientsApi.get(
      `/admin/school/${schoolId}/invoices`, {
        params: query
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const deleteInvoices = async (schoolId, ids) => {
  try {
    const response = await clientsApi.post(
      `/admin/school/${schoolId}/delete-invoices`, { ids }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getStudentsFile = () => async (dispatch, getState) => {
  const response = await clientsApi.get(`/admin/reports/students`, { responseType: "blob" });
  if (!response.headers['content-type'].includes('application/vnd.ms-excel')) return;
  const file = new Blob([response.data], { type: "application/vnd.ms-excel" });
  return file;
}